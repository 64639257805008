import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { isJWTValid, extractFromJWT, decodeJWT } from '../../../utils/JwtUtils';
import BaseService from '../../../services/BaseService';
import { useTranslation } from 'react-i18next';
import { usePbkAuth } from '../../../contexts/PbkAuthContext';
import { ScriptexAuthStatus } from '../../../models/AuthModels';
import i18n from 'i18next';
import StorageService from '../../../services/StorageService';

const SamlCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const hasFetched = useRef(false);
  const { t } = useTranslation('auth');
  const auth = usePbkAuth();

  const accountId = token ? (extractFromJWT(decodeJWT(token), 'account_id') as string) : null;

  useEffect(() => {
    // Prevent multiple executions
    if (hasFetched.current) return;
    hasFetched.current = true;

    if (token && isJWTValid(token)) {
      BaseService.recreateHttpClient(token);

      AuthService.exchangeSsoToken()
        .then((res) => {
          BaseService.clearToken();
          i18n.changeLanguage(res.data.user.language);
          StorageService.setLang(res.data.user.language);
          auth._implementationData.setAuthStatus((prev: ScriptexAuthStatus) => ({
            ...prev,
            isAuthenticated: true,
          }));
          StorageService.setLoginUrl(`/auth/${accountId}/saml-login?redirect=false`);
          navigate(`/clients/${accountId}/home`);
        })
        .catch(() => {
          navigate('/auth/login');
        });
    } else {
      navigate('/auth/login');
    }
  }, [navigate, token, accountId, auth]);

  return <p>{t('sso.processing')}</p>;
};

export default SamlCallback;
